body {
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.contact-card {
  text-align: center;
  background-color: #000;
  border-radius: 8px;
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 0 10px #0000001a;
}

h2, p {
  color: #fff;
}

.contact-details {
  margin-top: 20px;
}

p {
  margin: 10px 0;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
/*# sourceMappingURL=index.e788c647.css.map */
