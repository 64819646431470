body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.contact-card {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: black;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

h2,p {
    color: white;
}

.contact-details {
    margin-top: 20px;
}

p {
    margin: 10px 0;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}